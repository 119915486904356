import React, { Component } from "react";

class Footer extends Component {
render(){
    return(
        <div className="footer">
        <div className="copy">
        Copyright &copy;	2021

        </div>
        </div>
    )
}

}
export default Footer;
